body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* const date = new Date()

[
  {
    client_id: 4,

    month: 4,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 3,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 2,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 1,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,



  },

  {
    client_id: 4,

    month: 4,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 3,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 2,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 1,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,
  },

  {
    client_id: 4,

    month: 4,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 3,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 2,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,

    month: 1,
    cnt: 21,
    pay: y,
    inv: n,
    hc :null,
    pf: y,
    ts: null,
  }
] */

/* .css-1dlr9fi-indicatorContainer,.css-174whqh-indicatorContainer{
  margin-top: 0 !important;
} */
button{
  border: none !important;
}


